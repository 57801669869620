/* index.css */
/* Tailwind CSS imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global Styles */
body {
  @apply bg-white text-gray-800 dark:bg-gray-900 dark:text-gray-200;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

/* Smooth Scrolling */
html {
  scroll-behavior: smooth;
}

/* Disable scrolling when the mobile menu is open */
body.menu-open {
  overflow: hidden;
}
